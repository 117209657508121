function AlertIcon() {
  return (
    <svg
      className="sc-alert__icon"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" stroke="currentColor" />
      <path
        d="M9.05992 12.75H10.9359V14.5H9.05992V12.75ZM9.12992 4.504H10.8799V7.388L10.4179 11.714H9.59192L9.12992 7.388V4.504Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default AlertIcon;
