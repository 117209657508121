import React, { ComponentPropsWithoutRef } from 'react';
import AlertContent from './AlertContent';
import AlertTitle from './AlertTitle';
import AlertIcon from './AlertIcon';

type AlertProps = {
  children: React.ReactNode;
  variant?: 'success' | 'warning' | 'info' | 'field-warning' | 'light' | 'info-light';
  size?: 'sm' | 'md' | 'xs';
} & ComponentPropsWithoutRef<'div'>;

function Alert({
  children,
  variant = 'warning',
  size = 'md',
  className,
  ...alertHtmlAttributes
}: AlertProps): React.JSX.Element {
  return (
    <div
      className={`sc-alert sc-alert--${variant} sc-alert--${size} ${className || ''}`}
      {...alertHtmlAttributes}
    >
      {children}
    </div>
  );
}

Alert.Icon = AlertIcon;
Alert.Title = AlertTitle;
Alert.Content = AlertContent;

export default Alert;
