import type React from 'react';

type AlertTitleProps = {
  children: React.ReactNode;
};

function AlertTitle({ children }: AlertTitleProps): React.JSX.Element {
  return <div className="sc-alert__title">{children}</div>;
}

export default AlertTitle;
