import type React from 'react';

type AlertContentProps = {
  children: React.ReactNode;
};

function AlertContent({ children }: AlertContentProps): React.JSX.Element {
  return <div className="sc-alert__content">{children}</div>;
}

export default AlertContent;
